import React, {useEffect} from 'react'
import CustomButton from "../Common/Button/CustomButton";
import "./styles.css"

type PeriodSelectorType = {
    currentPeriod: string,
    setCurrentPeriod: React.Dispatch<React.SetStateAction<string>>
}

export default function PeriodSelector(props: PeriodSelectorType) {

    const {currentPeriod, setCurrentPeriod} = props;

    return (
        <div className={'period-selector-container mt-2 '}>
            <CustomButton text={'1m'} onClick={() => setCurrentPeriod('1m')} selected={currentPeriod === '1m'}/>
            <CustomButton text={'3m'} onClick={() => setCurrentPeriod('3m')} selected={currentPeriod === '3m'}/>
            <CustomButton text={'5m'} onClick={() => setCurrentPeriod('5m')} selected={currentPeriod === '5m'}/>
            <CustomButton text={'15m'} onClick={() => setCurrentPeriod('15m')} selected={currentPeriod === '15m'}/>
            <CustomButton text={'1h'} onClick={() => setCurrentPeriod('1h')} selected={currentPeriod === '1h'}/>
            <CustomButton text={'4h'} onClick={() => setCurrentPeriod('4h')} selected={currentPeriod === '4h'}/>
            <CustomButton text={'1d'} onClick={() => setCurrentPeriod('1d')} selected={currentPeriod === '1d'}/>
            <CustomButton text={'1s'} onClick={() => setCurrentPeriod('1w')} selected={currentPeriod === '1w'}/>
        </div>
    );

}