import Binance, {Candle, CandleChartResult, MyTrade, ReconnectingWebSocketHandler} from 'binance-api-node'
import axios, {AxiosResponse} from "axios";

class BinanceClient {
    private static _instance: any;

    public static getInstance() {
        if (BinanceClient._instance === undefined) {
            BinanceClient._instance = Binance({
                apiKey: process.env.REACT_APP_API_KEY,
                apiSecret: process.env.REACT_APP_API_SECRET,
            });
        }

        return BinanceClient._instance;
    }
}

export type binanceClientConfig = {
    currentSymbol: string,
    currentPeriod: string
    callback?: (data?: any) => void,
    timestampLastCandle?: number
}

export const getPrices = async () : Promise<any> => {
    return BinanceClient.getInstance().prices();
}

// Returns 1000 candles of the symbol and period given (for get historical data), as the limit is 1000 candles
// we can play with the endTime for get more
export const getCandles = async (props : binanceClientConfig) : Promise<CandleChartResult[]> => {
    let endtime = props.timestampLastCandle ? {
        endTime: props.timestampLastCandle
    } : {};

    let candles = await BinanceClient.getInstance().candles({
        symbol: props.currentSymbol,
        interval: props.currentPeriod,
        limit: 1000,
        ...endtime
    });

    props.callback?.(candles);

    return candles;
}

// subscribes to a websocket that returns the current tick for the symbol and period given
export const subscribeWsGetCandles = (props: binanceClientConfig) : ReconnectingWebSocketHandler => {
    return BinanceClient.getInstance().ws.candles(props.currentSymbol, props.currentPeriod, (candle: Candle) => {
        props.callback?.(candle);
    })
}

// Get the trades for a symbol
export const getTrades = async (currentSymbol: string) : Promise<AxiosResponse<MyTrade[]>>  => {
    return await axios.get(`http://localhost:9005/trades/${currentSymbol}`);
}


