import React, {useEffect, useState} from 'react'
import AutoCompleteInput from "../Common/CustomInput/AutoCompleteInput";
import {getPrices} from "../../models/BinanceClient";

type SymbolSelectorType = {
    currentSymbol: string,
    setCurrentSymbol: React.Dispatch<React.SetStateAction<string>>
}

export default function SymbolSelector(props: SymbolSelectorType) {

    const [symbols, setSymbols] = useState<string[]>([]);


    useEffect(() => {
        (async () => {
            let symbols = await getPrices();
            setSymbols(Object.keys(symbols));
        })();
    }, [])


    return (
        <AutoCompleteInput
        options={symbols}
        defaultValue={props.currentSymbol}
        changeStateFunction={props.setCurrentSymbol}
    />);

}