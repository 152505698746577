import React, {createContext, useContext, useMemo, useState} from 'react'

type GlobalContextType = {
    currentSymbol: string,
    setCurrentSymbol: React.Dispatch<React.SetStateAction<string>>,

    currentPeriod: string,
    setCurrentPeriod: React.Dispatch<React.SetStateAction<string>>,

    showNewBacktestModal: boolean,
    setShowNewBacktestModal: React.Dispatch<React.SetStateAction<boolean>>,

    showBacktestListModal: boolean,
    setShowBacktestListModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const initialGlobalContext : GlobalContextType = {
  currentSymbol : '',
  setCurrentSymbol: () => {},
  currentPeriod: '',
  setCurrentPeriod: () => {},
  showNewBacktestModal: true,
  setShowNewBacktestModal: () => {},
  showBacktestListModal: true,
  setShowBacktestListModal: () => {}
};

const GlobalContext = createContext<GlobalContextType>(initialGlobalContext);

export function GlobalProvider(props: any)
{
    const [currentSymbol, setCurrentSymbol] = useState<string>('LUNAEUR');
    const [currentPeriod, setCurrentPeriod] = useState<string>('1h');
    const [showNewBacktestModal, setShowNewBacktestModal] = useState<boolean>(false);
    const [showBacktestListModal, setShowBacktestListModal] = useState<boolean>(false);

    const value = useMemo(() => {
        return ({
            currentSymbol,
            currentPeriod,
            setCurrentSymbol,
            setCurrentPeriod,
            showNewBacktestModal,
            setShowNewBacktestModal,
            showBacktestListModal,
            setShowBacktestListModal,
        })
    }, [currentSymbol, currentPeriod, showNewBacktestModal, showBacktestListModal])

    return <GlobalContext.Provider value={value} {...props} />
}

export function useGlobalContext() {
    const context = useContext(GlobalContext);

    if (!context) {
        throw new Error('Estas usando el hook en un componente que no pertenece a ese context')
    }

    return context;
}