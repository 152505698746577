// Makes binary search through the array, searching for element, if it's not found picks the one is closer to him
export const binarySearchCloser = (array: Array<number>, element: number, originalArr: Array<number>): number => {
    let middleElement = Math.floor(array.length / 2);

    if (array[middleElement] === element) {
        return array[middleElement];
    } else if (element > array[middleElement] && array.length > 1) {
        return binarySearchCloser(array.splice(middleElement, array.length), element, originalArr);
    } else if (element < array[middleElement] && array.length > 1) {
        return binarySearchCloser(array.splice(0, middleElement), element, originalArr);
    } else {
        // We check to which one are we closer
        let index = originalArr.indexOf(array[middleElement]);
        return array[middleElement] - element >= originalArr[index + 1] - element ? array[middleElement] : originalArr[index + 1];
    }
}

// Counts the decimals of a number
export const countDecimals = (number: number | undefined) => {
    if (number === undefined) return 0;

    if(Math.floor(number.valueOf()) === number.valueOf()) return 0;
    return number.toString().split(".")[1].length || 0;
}