import axios from "axios";

type newBacktestData = {
    fromDate: Date,
    toDate: Date,
    backtestSymbol: string,
    backtestPeriod: string,
    rules: Object
}

export function sendToBacktest(data: newBacktestData)
{
    (async () => {
        const response = await axios.post(`http://localhost:9005/backtest`, data)
        console.log(response);
    })();
}