import React from 'react'
import SymbolSelector from "../SymbolSelector/SymbolSelector";
import PeriodSelector from "../PeriodSelector/PeriodSelector";
import "./styles.css"
import CustomButton from "../Common/Button/CustomButton";
import {useGlobalContext} from "../../contexts/Global/GlobalContext";

export default function Header() {

    const {
        setShowNewBacktestModal,
        currentSymbol,
        setCurrentSymbol,
        currentPeriod,
        setCurrentPeriod,
        setShowBacktestListModal
    } = useGlobalContext();

    return (
        <div className={'header-container d-flex p-3'}>
            <div className={'left-panel d-flex flex-column pe-2'}>
                <div className={'d-flex justify-content-between'}>
                    <span className={'title px-2'}>TraderBot</span>
                    <SymbolSelector
                        currentSymbol={currentSymbol}
                        setCurrentSymbol={setCurrentSymbol}

                    />
                </div>
                <PeriodSelector
                    currentPeriod={currentPeriod}
                    setCurrentPeriod={setCurrentPeriod}
                />
            </div>
            <div className={'right-panel d-flex flex-column ps-2'}>
                {/*<div className={'mb-2'}>*/}
                {/*    <CustomButton*/}
                {/*        text={'New Backtest'}*/}
                {/*        onClick={() => setShowNewBacktestModal(true)}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*    <CustomButton*/}
                {/*        text={'Backtest List'}*/}
                {/*        onClick={() => setShowBacktestListModal(true)}*/}
                {/*    />*/}

            </div>
        </div>
    );
}