import React, {useEffect} from 'react';
import "./styles.css";


type ButtonType = {
    text: string,
    onClick: () => any,
    selected?: boolean
}

export default function CustomButton(props: ButtonType) {
    return <button
        className={'custom-button rounded w-100 h-100 py-2 px-2 mx-1 ' + (props.selected ? 'selected-button' : '')}
        onClick={props.onClick}
    >
        {props.text}
    </button>;
}