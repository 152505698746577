import React from 'react';
import './assets/css/App.css';
import CandleChart from "./components/Chart/CandleChart";
import {getCandles, getTrades, subscribeWsGetCandles} from "./models/BinanceClient";
import Header from "./components/Header/Header";
import {useGlobalContext} from "./contexts/Global/GlobalContext";
import Modal from "./components/Common/Modal/Modal";
import {BacktestFormProvider} from "./contexts/BacktestForm/BacktestForm";
import NewBacktestForm from "./components/NewBacktest/NewBacktestForm";
import BacktestList from "./components/BacktestList/BacktestList";

function App() {

    const {
        showNewBacktestModal,
        setShowNewBacktestModal,
        showBacktestListModal,
        setShowBacktestListModal,
    } = useGlobalContext();

    return (
        <>
            {
                showNewBacktestModal &&
                <BacktestFormProvider>
                    <Modal closeModalFunction={() => setShowNewBacktestModal(false)}>
                        <NewBacktestForm/>
                    </Modal>
                </BacktestFormProvider>

            }
            {
                showBacktestListModal &&
                <BacktestFormProvider>
                    <Modal closeModalFunction={() => setShowBacktestListModal(false)}>
                        <BacktestList/>
                    </Modal>
                </BacktestFormProvider>

            }
            <div className="App">
                <Header/>
                <CandleChart
                    loadTradesFunction={getTrades}
                    loadCandlesFunction={getCandles}
                    webserviceChartFunction={subscribeWsGetCandles}
                />
            </div>
        </>
    );
}

export default App;
