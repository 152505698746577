import React from 'react';
import "./styles.css";

import {FaWindowClose} from "react-icons/fa";

type ModalType = {
    closeModalFunction: () => void
    children: JSX.Element
}

export default function Modal(props: ModalType) {

    return <div className={'custom-modal custom-modal-background'} onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        props.closeModalFunction()
        e.stopPropagation();
    }}>
        <div className={'container vh-100 py-5 px-5'}>
            <div className={"row custom-modal-container w-100 py-5 px-5 align-self-center rounded text-break"}
                 onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                     e.stopPropagation()
                 }}>
                {props.children}
                <div className={'close-icon d-flex justify-content-end'}
                     onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                         props.closeModalFunction()
                         e.stopPropagation();
                     }}>
                    <FaWindowClose/>
                </div>

            </div>
        </div>
    </div>;
}