import React, {useEffect, useRef, useState} from 'react';
import "./styles.css";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import JSONInput from "react-json-editor-ajrm/index";
import {theme_colors, localeEn, theme_style} from "../../utils/jsonEditorConfig";
import {useBacktestFormContext} from "../../contexts/BacktestForm/BacktestForm";
import SymbolSelector from "../SymbolSelector/SymbolSelector";
import PeriodSelector from "../PeriodSelector/PeriodSelector";
import CustomButton from "../Common/Button/CustomButton";
import axios, {AxiosResponse} from "axios";
import {sendToBacktest} from "./NewBacktestData";

type NewBacktestFormType = {}

export default function NewBacktestForm(props: NewBacktestFormType) {

    const [modalHeight, setModalHeight] = useState(0);
    const {fromDate, toDate, setFromDate, setToDate, rules, setRules, backtestPeriod, setBacktestPeriod, backtestSymbol, setBacktestSymbol} = useBacktestFormContext();

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        modalRef.current && setModalHeight(modalRef.current.offsetHeight);

        (async () => {
            const response : AxiosResponse<any> = await axios.get(`http://localhost:9005/config`);
            setRules(response.data);
        })();
    }, [])


    return <>
        <div ref={modalRef} className="col-12 d-flex mb-4">
            <div className={'col-6 d-flex flex-column pe-5'}>
                <div className={'col-12 title mb-2'}>New Backtest:</div>
                <div className="col-12 d-flex mb-3">
                    <div className="col-6">
                        <div className={'mb-1'}>From:</div>
                        <DatePicker
                            showPopperArrow={false}
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            selectsStart
                            startDate={fromDate}
                            endDate={toDate}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown={true}
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="col-6">
                        <div className={'mb-1'}>To:</div>
                        <DatePicker
                            showPopperArrow={false}
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            selectsEnd
                            startDate={fromDate}
                            endDate={new Date()}
                            minDate={fromDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown={true}
                        />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className={'mb-1'}>Symbol:</div>
                    <SymbolSelector
                        currentSymbol={backtestSymbol}
                        setCurrentSymbol={setBacktestSymbol}
                    />
                </div>
                <div className="col-12 mb-4">
                    <div className={'mb-1'}>Periodicity:</div>
                    <PeriodSelector
                        currentPeriod={backtestPeriod}
                        setCurrentPeriod={setBacktestPeriod}
                    />
                </div>
                <div className="col-12 d-flex mt-auto">
                    <CustomButton text={'Run Test'} onClick={() => {
                        sendToBacktest({
                            fromDate,
                            toDate,
                            backtestSymbol,
                            backtestPeriod,
                            rules
                        })

                    }} />
                </div>
            </div>
            <div className={'col-6 d-flex flex-column'}>
                <div className={'col-12'}>Bot Rules:</div>
                <div className={'col-12'} style={{position:'relative', maxHeight: `${modalHeight}px` }}>
                    <JSONInput
                        style={theme_style}
                        colors={theme_colors}
                        locale={localeEn}
                        placeholder={rules}
                        width={'100%'}
                        height={'100%'}
                    />
                </div>
            </div>
        </div>
    </>;
}