// @ts-ignore
import { checkCoordinateOnSegment } from 'klinecharts/lib/shape/shapeHelper';
import {
    ShapeCheckOnParams,
    ShapeCreateDataSourceParams,
    ShapeDrawExtendParams,
    ShapeTemplate
} from "klinecharts/types/Shape";

export const RULER_MARK : ShapeTemplate = {
    name: 'measure',
    totalStep: 3,
    checkEventCoordinateOnShape: ({ eventCoordinate }: ShapeCheckOnParams) => {
        return checkCoordinateOnSegment(eventCoordinate.x, eventCoordinate.y, eventCoordinate);
    },
    createShapeDataSource: ({ coordinates, points } : ShapeCreateDataSourceParams) => {
        if (coordinates.length === 2) {
            const startPrice = points[0]!.value;
            const endPrice = points[1]!.value;
            // @ts-ignore
            const priceDiff = endPrice - startPrice;
            // @ts-ignore
            const percent = priceDiff / (startPrice / 100);
            const numberFormatter = new Intl.NumberFormat('es-ES', {
                maximumSignificantDigits: 2,
            });

            // @ts-ignore
            const p1x: number = coordinates[0]!.x;
            // @ts-ignore
            const p1y: number = coordinates[0]!.y;
            // @ts-ignore
            const p2x: number = coordinates[1]!.x;
            // @ts-ignore
            const p2y: number = coordinates[1]!.y;

            // @ts-ignore
            let firstPointBars : number = points[0]!.dataIndex;

            // @ts-ignore
            let secondPointBars : number = points[1]!.dataIndex;

            // @ts-ignore
            let diffTimePoints : number = Math.floor(points[1]!.timestamp - points[0]!.timestamp)/1000/60;

            return [
                {
                    type: 'polygon',
                    dataSource: [
                        [
                            {
                                x: p1x + (p2x - p1x) / 2,
                                y: coordinates[0].y,
                            },
                            {
                                x: p1x + (p2x - p1x) / 2,
                                y: coordinates[1].y,
                            },
                        ],
                        [
                            {
                                x: p1x,
                                y: p1y + (p2y - p1y) / 2,
                            },
                            {
                                x: p2x,
                                y: p1y + (p2y - p1y) / 2,
                            },
                        ],
                    ],
                },
                {
                    type: 'polygon',
                    dataSource: [
                        [
                            { ...coordinates[0] },
                            { x: p2x, y: p1y },
                            { ...coordinates[1] },
                            { x: p1x, y: p2y },
                        ],
                    ],
                },
                {
                    type: 'text',
                    dataSource: [
                        {
                            x: p1x + (p2x - p1x) / 2,
                            y: p2y,
                            text: `${numberFormatter.format(priceDiff)}€ (${numberFormatter.format(percent)}%) ${secondPointBars - firstPointBars} Bars, ${diffTimePoints} minutes`,
                        },
                    ],
                },
            ];
        }
        return [];
    },
    drawExtend: ({ctx, dataSource} : ShapeDrawExtendParams) => {
        const arrowsDataSource : any = dataSource?.[0]?.dataSource;
        const polygonDataSource : any = dataSource?.[1]?.dataSource;
        const measureDataSource : any = dataSource?.[2]?.dataSource;

        if (!arrowsDataSource) return;

        // fill rectangle
        const rectangleWidth =
            polygonDataSource[0][1].x - polygonDataSource[0][0].x;
        const rectangleHeight =
            polygonDataSource[0][2].y - polygonDataSource[0][0].y;
        const isShort = rectangleHeight > 0;
        ctx.fillStyle = isShort ? 'rgba(255,82,82,0.1)' : 'rgba(33,150,243,0.1)';
        ctx.fillRect(
            polygonDataSource[0][0].x,
            polygonDataSource[0][0].y,
            rectangleWidth,
            rectangleHeight,
        );

        // arrows
        ctx.beginPath();
        const minRectangleSize = 10;
        const strokeStyle = isShort ? 'rgba(255,82,82,1)' : 'rgba(33,150,243,1)';

        // vertical arrow
        ctx.strokeStyle = strokeStyle;
        ctx.moveTo(arrowsDataSource[0][0].x, arrowsDataSource[0][0].y);
        ctx.lineTo(arrowsDataSource[0][1].x, arrowsDataSource[0][1].y);

        // draw arrows if rectangle is not small
        if (Math.abs(rectangleHeight) > minRectangleSize) {
            ctx.lineTo(
                arrowsDataSource[0][1].x + (isShort ? -5 : 5),
                arrowsDataSource[0][1].y + (isShort ? -5 : 5),
            );
            ctx.moveTo(arrowsDataSource[0][1].x, arrowsDataSource[0][1].y);
            ctx.lineTo(
                arrowsDataSource[0][1].x + (isShort ? 5 : -5),
                arrowsDataSource[0][1].y + (isShort ? -5 : 5),
            );
        }
        ctx.stroke();

        // horizontal
        ctx.strokeStyle = strokeStyle;
        ctx.moveTo(arrowsDataSource[1][0].x, arrowsDataSource[1][0].y);
        ctx.lineTo(arrowsDataSource[1][1].x, arrowsDataSource[1][1].y);

        // draw arrows if rectangle is not small
        if (Math.abs(rectangleWidth) > minRectangleSize) {
            const isLeft = rectangleWidth < 0;
            ctx.lineTo(
                arrowsDataSource[1][1].x + (isLeft ? 5 : -5),
                arrowsDataSource[1][1].y + 5,
            );
            ctx.moveTo(arrowsDataSource[1][1].x, arrowsDataSource[1][1].y);
            ctx.lineTo(
                arrowsDataSource[1][1].x + (isLeft ? 5 : -5),
                arrowsDataSource[1][1].y - 5,
            );
        }
        ctx.stroke();

        // measure box
        ctx.fillStyle = isShort ? 'rgba(255,82,82,1)' : 'rgba(33,150,243,1)';
        const textSize = ctx.measureText(measureDataSource[0].text);
        const measureBoxX = measureDataSource[0].x - textSize.width / 2 - 10;
        const measureBoxY = measureDataSource[0].y + (isShort ? 10 : -40);
        const measureBoxWidth = textSize.width + 20;
        const measureBoxHeight = 30;
        ctx.fillRect(measureBoxX, measureBoxY, measureBoxWidth, measureBoxHeight);
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';
        ctx.textBaseline = 'middle';
        ctx.font = ctx.font.replace(/\d+px/, '12px');
        ctx.fillText(
            measureDataSource[0].text,
            measureDataSource[0].x,
            measureBoxY + measureBoxHeight / 2,
        );
    },
};