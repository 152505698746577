import React, {useState} from 'react'
import "./styles.css";

type AutoCompleteInputType = {
    options: string[],
    defaultValue?: string,
    changeStateFunction: any
}


export default function AutoCompleteInput(
    {
        options,
        defaultValue = '',
        changeStateFunction,
    } : AutoCompleteInputType
) {

    const [filteredOptionList, setFilteredOptionList] = useState<string[]>([]);
    const [currentOptionIndex, setCurrentOptionIndex] = useState<number>(0);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [input, setInput] = useState<string>(defaultValue);


    const onSelect = (event: any) => {
        setCurrentOptionIndex(parseInt(event.currentTarget.value));
    }

    const onClick = (event: any) => {
        setFilteredOptionList([]);
        setInput(event.currentTarget.innerText);
        setCurrentOptionIndex(0);
        setShowSuggestions(false);

        changeStateFunction(event.currentTarget.innerText);
    };

    const onChange = (event: any) => {
        const userInput = event.currentTarget.value;
        setInput(event.currentTarget.value);

        if (userInput.length >= 3) {
            // Filter our suggestions that don't contain the user's input
            const suggestions = options.filter((suggestion: any) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);

            setFilteredOptionList(suggestions);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }

        setCurrentOptionIndex(0);
    }

    const SuggestionsListComponent = () => {
        return filteredOptionList.length ? (
            <div className="suggestions rounded w-100">
                {filteredOptionList.map((suggestion:string, index: number) => {

                    let className;
                    // Flag the active suggestion with a class
                    if (index === currentOptionIndex) {
                        className = 'item suggestion-active p-2 px-3 w-100 rounded';
                    } else {
                        className = 'item p-2 px-3 w-100 rounded'
                    }

                    return (
                        <div className={className} key={suggestion} onClick={onClick}>
                            {suggestion}
                        </div>
                    );
                })}
            </div>
        ) : (
            <div className="no-suggestions item p-2 px-3 w-100 rounded">
                <em>No suggestions</em>
            </div>
        );
    };

    return (
        <div className={"pairSelectorContainer w-100"}>
            <input
                className={'autocomplete-input rounded p-2 w-100'}
                type="text"
                onChange={onChange}
                onSelect={onSelect}
                value={input}
            />
            {showSuggestions && input && <SuggestionsListComponent />}
        </div>);

}