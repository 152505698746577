import React, {createContext, useContext, useMemo, useState} from 'react'

type BacktestFormContextType = {
    backtestSymbol: string,
    setBacktestSymbol: React.Dispatch<React.SetStateAction<string>>,

    backtestPeriod: string,
    setBacktestPeriod: React.Dispatch<React.SetStateAction<string>>,

    fromDate: Date,
    setFromDate: React.Dispatch<React.SetStateAction<Date | [(Date | null), (Date | null)] | null>>,
    toDate: Date,
    setToDate: React.Dispatch<React.SetStateAction<Date | [(Date | null), (Date | null)] | null>>,
    rules: Object,
    setRules: React.Dispatch<React.SetStateAction<string>>,
}

const initialGlobalContext : BacktestFormContextType = {
    backtestSymbol: '',
    setBacktestSymbol: () => {},
    backtestPeriod: '',
    setBacktestPeriod: () => {},
    fromDate : new Date(),
    setFromDate: () => {},
    toDate : new Date(),
    setToDate: () => {},
    rules : {},
    setRules: () => {},
};

const BacktestFormContext = createContext<BacktestFormContextType>(initialGlobalContext);

export function BacktestFormProvider(props: any)
{
    const [backtestSymbol, setBacktestSymbol] = useState<string>('LUNAEUR');
    const [backtestPeriod, setBacktestPeriod] = useState<string>('1h');
    const [fromDate, setFromDate] = useState<Date>(new Date());
    const [toDate, setToDate] = useState<Date>(new Date());
    const [rules, setRules] = useState<string>();

    const value = useMemo(() => {
        return ({
            fromDate,
            setFromDate,
            toDate,
            setToDate,
            rules,
            setRules,
            backtestSymbol,
            setBacktestSymbol,
            backtestPeriod,
            setBacktestPeriod
        })
    }, [fromDate, toDate, rules, backtestSymbol, backtestPeriod])

    return <BacktestFormContext.Provider value={value} {...props} />
}

export function useBacktestFormContext() {
    const context = useContext(BacktestFormContext);

    if (!context) {
        throw new Error('Estas usando el hook en un componente que no pertenece a ese context')
    }

    return context;
}