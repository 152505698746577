import React, {useEffect, useRef} from 'react'
import {init, dispose, Annotation} from 'klinecharts'
import {BOLLconfig, DMIconfig, MAconfig, mainChartConfig, RSIconfig} from './config'
import {Chart} from "klinecharts/types/Chart";
import {useGlobalContext} from "../../contexts/Global/GlobalContext";
import {Candle, CandleChartResult, MyTrade, ReconnectingWebSocketHandler} from "binance-api-node";
import {KLineData} from "klinecharts/types/Common";
import {binanceClientConfig} from "../../models/BinanceClient";
import {AxiosResponse} from "axios";
import {formatCandle, formatTrades} from "../../helpers/formatter";
import {countDecimals} from "../../utils/math";
import {RULER_MARK} from "./Tools/Rules";

export type CandleChartType = {
    loadCandlesFunction: (props: binanceClientConfig) => Promise<CandleChartResult[]>
    loadTradesFunction: (currentSymbol: string) => Promise<AxiosResponse<MyTrade[]>>,
    webserviceChartFunction?: (props: binanceClientConfig) => ReconnectingWebSocketHandler
}

export default function CandleChart(props: CandleChartType) {

    const {currentSymbol, currentPeriod} = useGlobalContext();

    let disposeWebserviceFunction = useRef<ReconnectingWebSocketHandler>();
    let chart: Chart | null;
    let blockLoadData : boolean = false;

    // Load the candles based on the symbol and period given
    const loadCandles = async (timestampLastCandle?: number) : Promise<KLineData[]> => {
       if (blockLoadData) {
           return chart!.getDataList();
       }

        // Load the candles and add them to the chart
        let candlesData : CandleChartResult[] = await props.loadCandlesFunction({
            currentSymbol: currentSymbol,
            currentPeriod: currentPeriod,
            timestampLastCandle: timestampLastCandle
        });

        let candles: KLineData[] = candlesData.map((candle: CandleChartResult) => formatCandle(candle));
        chart!.setPriceVolumePrecision(countDecimals(candles[0].close), countDecimals(candles[0].volume));

        chart!.applyMoreData(candles);

        // We block loading more as we are getting already the latest data
        if (candles.length === 1) {
            blockLoadData = true;
        }

        return candles;
    }

    // Load the trades, search for the closer candle and add them to the chart
    const loadTrades = async (candles: KLineData[]) : Promise<MyTrade[]> => {
        let trades: Annotation[] = [];
        let tradesData = await props.loadTradesFunction(currentSymbol);
        await tradesData.data.forEach((trade: MyTrade) => {
            let annotation = formatTrades(candles, trade)

            if (annotation) {
                trades.push(annotation);
            }
        });

        // chart!.createShape()
        chart!.createAnnotation(trades);

        return tradesData.data;
    }

    // dispose method for this component
    const disposeChart = (): void => {
        dispose('chart');
        disposeWebserviceFunction.current?.();
    };

    useEffect(() => {
        chart = init('chart', mainChartConfig)

        if (chart === null) {
            throw new Error('Chart loading failed');
        }

        chart.createTechnicalIndicator(MAconfig, true, {id: 'candle_pane'});
        chart.createTechnicalIndicator(BOLLconfig, true, {id: 'candle_pane'});
        chart.createTechnicalIndicator(RSIconfig);
        chart.createTechnicalIndicator(DMIconfig);

        chart.addShapeTemplate([RULER_MARK])

        chart.zoomAtDataIndex(20, 0, 0);
        chart.setOffsetRightSpace(chart.getWidth().total / 2.5);
        chart.setDataSpace(300);
        chart.resize();

        (async () => {
            let candles = await loadCandles();
            await loadTrades(candles);
        })();

        // Load more data of the latest candle for update the price
        chart!.loadMore(((timestamp: number) => {
            (async () => {
                let candles = await loadCandles(timestamp);
                await loadTrades(candles);
            })();
        }));

        // Subscribe to the webservice
        disposeWebserviceFunction.current = props.webserviceChartFunction?.({
            currentSymbol: currentSymbol,
            currentPeriod: currentPeriod,
            callback: (candle: Candle) => {
                chart!.updateData(formatCandle(candle));
            }
        });

        return () => disposeChart();
    }, [currentSymbol, currentPeriod]);

    return (<>
        <div id="chart" className="k-line-chart"/>
            <button
                key={1}
                onClick={_ => {
                    console.log('ola');
                    chart!.createShape(RULER_MARK)
                }}>
                Ruler
            </button>
    </>

        )
}
