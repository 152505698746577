import React, {useEffect, useRef, useState} from 'react';
import "./styles.css";

import {useBacktestFormContext} from "../../contexts/BacktestForm/BacktestForm";



type BacktestFormType = {}

export default function BacktestList(props: BacktestFormType) {

    const {fromDate, toDate, setFromDate, setToDate, rules, setRules, backtestPeriod, setBacktestPeriod, backtestSymbol, setBacktestSymbol} = useBacktestFormContext();


    return <>
        <div className="col-12 d-flex mb-4">
            <div className={'col-12 title mb-2'}>Backtest List:</div>
        </div>
    </>;
}