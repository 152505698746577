import {Candle, CandleChartResult, MyTrade} from "binance-api-node";
import {KLineData} from "klinecharts/types/Common";
import {Annotation} from "klinecharts";
import {binarySearchCloser} from "../utils/math";

export const formatCandle = (candle: Candle | CandleChartResult): KLineData => {
    return {
        timestamp: candle.closeTime,
        open: parseFloat(candle.open),
        high: parseFloat(candle.high),
        low: parseFloat(candle.low),
        close: parseFloat(candle.close),
        volume: Math.ceil(parseFloat(candle.volume)),
    }
}

export const formatTrades = (candles: KLineData[], candle: MyTrade): Annotation | undefined => {
    let timestamps: number[] = candles.map((candles: KLineData) => candles.timestamp)

    // If it's lower than the lower time stamp is because the operation is not in this candles data set
    if (candle['time'] < timestamps[0]) {
        return undefined;
    }

    let closestTimestamp: number = binarySearchCloser(timestamps, candle['time'], timestamps.slice());

    return {
        point: {
            timestamp: closestTimestamp,
            value: parseFloat(candle.price)
        },
        styles: {
            symbol: {
                color: (candle.isBuyer) ? '#ffffff' : '#ffdf00',
            }
        },
    }
}